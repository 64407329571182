




































import { Component, Vue, Watch } from "vue-property-decorator";
import ImportService from "@/services/ImportService";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class ImportUsersModal extends Vue {
  private file: File | null = null;
  public showImportModal: boolean = false;
  private isLoadingImportModal: boolean = false;
  private errorReport: string = "";

  @Watch("file")
  onFileChange(newValue: File | null, oldValue: File | null) {
    if (newValue !== oldValue) {
      this.errorReport = ""; // Clear the error message when a new file is selected
    }
  }

  public openImportModal() {
    this.showImportModal = true;
  }

  public closeImportModal() {
    if (!this.isLoadingImportModal) {
      this.resetModal();
    }
  }

  private validateFile(): boolean {
    const allowedExtensions = ["xls", "xlsx"];
    const fileExtension = this.file?.name
      .split(".")
      .pop()
      ?.toLowerCase();
    const maxSize = 5 * 1024 * 1024; // 5MB max size

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      this.errorReport =
        "Type de fichier invalide. Veuillez sélectionner un fichier Excel.";
      return false;
    }

    if (this.file!.size > maxSize) {
      this.errorReport =
        "Le fichier est trop volumineux. La taille maximale est de 5MB.";
      return false;
    }

    return true;
  }

  private async validate() {
    if (this.file && this.validateFile()) {
      const formData = new FormData();
      this.errorReport = "";
      formData.append("File", this.file);

      this.isLoadingImportModal = true;

      try {
        const response = await ImportService.ImportUsers(formData);
        this.isLoadingImportModal = false;

        if (response.status === 200) {
          this.handleSuccess(response.data.message);
        } else {
          this.handleError(response);
        }
      } catch (error) {
        this.handleError(error);
      }
    }
  }

  private handleSuccess(message: string) {
    let successMessage = message;
    this.$notify({
      group: "global",
      type: "success",
      title: "Importation réussie",
      text: successMessage.replace(/\n/g, "<br>"),
      duration: 30000,
    });

    this.$emit("handleImportSuccess");
    this.resetModal();
  }

  private handleError(error: any) {
    this.isLoadingImportModal = false;
    let errorMessage = "Erreur lors de l'importation du fichier."; // Default error message

    if (error.response && error.response.data) {
      errorMessage =
        typeof error.response.data === "object" && error.response.data.message
          ? error.response.data.message
          : error.response.data;
    } else if (error.message) {
      errorMessage = error.message;
    }

    this.$notify({
      group: "global",
      type: "error",
      title: "Fichier non importé",
      text: errorMessage.replace(/\n/g, "<br>"), // Replace newlines with <br> for HTML display
      duration: 30000,
    });
  }

  private resetModal() {
    this.showImportModal = false;
    this.file = null;
    this.errorReport = "";
  }
}
